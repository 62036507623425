import './App.css';
import ErrorPage from './components/error/ErrorPage';
import Home from './components/home';
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/" element={ <ErrorPage />} />
      <Route path="/error" element={ <ErrorPage />} />
    </Routes>
  );
}

export default App;
