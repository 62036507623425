
import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.css';

const ErrorPage = () => {
   const { t } = useTranslation();
   const returnToPartner = () => {
        window.location.href = 'https://bet3000.de';
    }
  return (
    <div className='errors'>
      <div className='errors-modal'>
        Service is currently unavailable
      </div>
    </div> 
  );
};

export default ErrorPage;